.footer {
  background-color: rgba(249, 249, 249, 1);
  margin-top: 50px;
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50px 50px 0 0;
}
.main {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 20% 40%;
  justify-content: space-between;
}
.main div:first-of-type {
  display: flex;
  flex-direction: column;
}
.main div:first-of-type img {
  height: 60px;
  width: fit-content;
}
.main div:first-of-type p {
  color: #2e052b;
  font-size: 18px;
  margin: 10px 0;
  line-height: 1.6;
}
.main > nav {
  display: flex;
  flex-direction: column;
}
.main > nav h3 {
  font-size: 25px;
}
.main > nav ul {
  display: flex;
  flex-direction: column;
}
.main > nav a {
  text-decoration: none;
  margin: 10px 0;
  font-size: 18px;
  color: #2e052b;
}
.main div:last-of-type {
  display: flex;
  flex-direction: column;
}
.main div:last-of-type nav {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 10px;
}
.main div:last-of-type nav p {
  line-height: 1.6;
}
.main div:last-of-type nav img {
  height: 20px;
  width: 20px;
  margin: 0 10px;
}
.main div:last-of-type nav:last-of-type {
  margin: 20px 0;
}
.main div:last-of-type nav:last-of-type img {
  height: 35px;
  width: 35px;
}
.footer > span {
  margin-top: 50px;
}
@media only screen and (max-width: 850px) {
  .footer {
    margin-top: 50px;
    padding: 20px 15px;
    border-radius: 20px 20px 0 0;
  }
  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .main div:first-of-type img {
    height: 60px;
  }
  .main div:first-of-type p {
    font-size: 15px;
  }
  .main div:first-of-type p br {
    display: none;
  }
  .main > nav {
    margin: 10px 0;
    flex-wrap: wrap;
  }
  .main > nav h3 {
    font-size: 18px;
  }
  .main > nav ul {
    margin: 10px 0;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
  }
  .main > nav a {
    font-size: 15px;
  }
  .main div:last-of-type nav {
    font-size: 15px;
    margin: 10px 0;
  }
  .main div:last-of-type nav img {
    margin: 0 10px 0 0;
  }
  .main div:last-of-type nav:last-of-type img {
    height: 25px;
    width: 25px;
  }
  .footer > span {
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
  }
}
