.sticky {
    position: sticky;
    background: white;
    top: 0;
    z-index: 999999999;
    display: flex;
    height: fit-content;
}
.header, .sticky {
  padding: 10px 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: fit-content;
}
.header img, .sticky img {
  height: 60px;
}
.header nav a, .sticky nav a {
  color: #2e052b;
  text-decoration: none;
  margin: 0 20px;
  font-size: 18px;
}
.ham {
  display: none;
}
@media only screen and (max-width: 850px) {
  .header, .sticky {
    padding: 10px 15px;
    justify-content: space-between;
  }
  .header img, .sticky img {
    height: 60px;
  }
  .header nav, .sticky nav {
    display: none;
  }
  .header .ham, .sticky .ham {
    display: block;
    width: 25px;
    height: 25px;
    transition: all 0.6s ease-in-out;
  }
  .header .show, .sticky .show {
    background-color: white;
    display: block;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 9999999;
  }
  .header .show a, .sticky .show a {
    padding: 10px 0;
  }
}
